import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Cookies from 'quasar/src/plugins/Cookies.js';
import quasarUserOptions from './quasar-user-options'

createApp(App).use(
    Quasar, 
    quasarUserOptions, 
    Notify, 
    Dialog,
    Cookies
).use(router).mount('#app')
