import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'



import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/SessionStorage.js';import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';import Cookies from 'quasar/src/plugins/Cookies.js';
// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Loading,
    Notify,
    Dialog,
    LocalStorage, 
    SessionStorage,
    QSpinnerGears,
    Cookies
  }
}